// useBookTime.tsx

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getCart, selectCart, updateCart } from "app/redux/cartSlice";
import {
  getSchedules,
  Schedule,
  AvailabilityType,
} from "app/redux/schedulesSlice";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { useQuery } from "app/utils/useQuery";
import { Path } from "app/path";
import { format, isSameDay, addMinutes, addDays, startOfDay } from "date-fns";
import cable from "app/cable";
import { toZonedTime } from "date-fns-tz";
import { getAppointmentGroupByBookingToken } from "app/redux/appointmentGroupSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { DualPricingPaymentTypes } from "../useBook";
import { ModalTypes } from "app/order/useOrder";

enum CartStatus {
  COMPLETED = "completed",
  PENDING = "pending",
}

const roundToNearestInterval = (minutes: number, interval: number): number => {
  return Math.round(minutes / interval) * interval;
};

const formatTimeToNearestInterval = (
  timeStr: string,
  interval: number
): string => {
  const totalMinutes = parseTime(timeStr); // Convert "HH:mm" to total minutes
  const roundedMinutes = roundToNearestInterval(totalMinutes, interval);
  return formatTime(roundedMinutes); // Convert total minutes back to "HH:mm"
};

// Helper functions (unchanged)
const parseTime = (timeStr: string): number => {
  const [hour, minute] = timeStr.split(":").map(Number);
  return hour * 60 + minute;
};

const formatTime = (minutes: number): string => {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  return `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
};

const generateTimeSlots = (
  timeStart: string,
  timeEnd: string,
  interval: number
): string[] => {
  const slots: string[] = [];
  const startMinutes = parseTime(timeStart);
  const endMinutes = parseTime(timeEnd);

  for (
    let minutes = startMinutes;
    minutes + interval <= endMinutes;
    minutes += interval
  ) {
    const slotStart = formatTime(minutes);
    const slotEnd = formatTime(minutes + interval);
    slots.push(`${slotStart} - ${slotEnd}`);
  }

  return slots;
};

// Fetch employee schedules (unchanged)
const getEmployeeSchedulesForDates = async (
  dispatch: any,
  employeeIds: number[],
  dates: Date[],
  nameKey: string
): Promise<{ [employeeId: number]: { [date: string]: Schedule } }> => {
  try {
    const schedulesArray = [];

    for await (const date of dates) {
      const schedules = await dispatch(
        getSchedules({ employeeIds, date, nameKey }) as any
      ).unwrap();
      schedulesArray.push(...schedules);
    }

    const scheduleMap: { [employeeId: number]: { [date: string]: Schedule } } =
      {};

    schedulesArray.forEach((schedule: Schedule) => {
      const { employeeId, date, availability } = schedule;
      if (!scheduleMap[employeeId]) {
        scheduleMap[employeeId] = {};
      }
      if (availability.status === AvailabilityType.UNSCHEDULED) {
        return;
      }
      scheduleMap[employeeId][date] = schedule;
    });

    return scheduleMap;
  } catch (error) {
    console.error("Error in getEmployeeSchedulesForDates:", error);
    throw error;
  }
};

// Find available time slots (unchanged)
const findAvailableTimeSlotsForDate = (
  schedules: { [employeeId: number]: { [date: string]: Schedule } },
  cartServices: any[],
  date: Date,
  interval: number,
  currentDate: Date,
  getTimeSlotsForReschedule: () => any[]
): string[] => {
  const dateString = date.toISOString().split("T")[0];
  const isSameDayFlag = isSameDay(currentDate, date);

  const employeeTimeSlots: { [employeeId: number]: string[] } = {};

  for (const service of cartServices) {
    const employeeId = service.employee.id;
    const schedule = schedules[employeeId]?.[dateString];

    if (!schedule || !schedule.availability) {
      console.warn(
        `No availability for employee ${employeeId} on ${dateString}`
      );
      return [];
    }

    const { availability, appointments, blockedTimes } = schedule;

    // Generate all possible time slots based on the availability
    const availableTimeSlots = generateTimeSlots(
      availability.timeStart,
      availability.timeEnd,
      interval
    );

    // Filter time slots to remove those that overlap with appointments or block times
    const filteredTimeSlots = availableTimeSlots.filter((slot) => {
      const [slotStartStr] = slot.split(" - ");
      const slotStartTime = parseTime(slotStartStr);

      // Filter out slots in the past if it's the same day
      if (
        isSameDayFlag &&
        slotStartTime < currentDate.getHours() * 60 + currentDate.getMinutes()
      ) {
        return false;
      }

      // Check for overlaps with appointments
      for (const appointment of appointments) {
        const appointmentStartTime = parseTime(
          appointment.startTime.slice(11, 16)
        );
        const appointmentEndTime = parseTime(appointment.endTime.slice(11, 16));
        if (
          slotStartTime < appointmentEndTime &&
          slotStartTime + interval > appointmentStartTime
        ) {
          return false;
        }
      }

      for (const blockTime of blockedTimes) {
        const blockStartTime = parseTime(blockTime.startTime.slice(11, 16));
        const blockEndTime = parseTime(blockTime.endTime.slice(11, 16));

        if (
          slotStartTime < blockEndTime &&
          slotStartTime + interval > blockStartTime
        ) {
          return false;
        }
      }

      return true;
    });

    employeeTimeSlots[employeeId] = filteredTimeSlots.map(
      (slot) => slot.split(" - ")[0]
    );
  }

  // Find common slots where all services can be scheduled consecutively
  const firstEmployeeId = cartServices[0].employee.id;
  const firstEmployeeSlots = employeeTimeSlots[firstEmployeeId] || [];

  const serviceDurations = cartServices.map((service) => service.duration);

  const availableTimeSlots: string[] = [];

  for (const startTimeStr of firstEmployeeSlots) {
    let canScheduleAll = true;
    let currentTime = parseTime(startTimeStr);

    for (let i = 0; i < cartServices.length; i++) {
      const service = cartServices[i];
      const employeeId = service.employee.id;
      const employeeSlots = employeeTimeSlots[employeeId] || [];
      const serviceDuration = serviceDurations[i];

      // Check if all slots required for the service duration are available
      for (
        let durationCheckTime = currentTime;
        durationCheckTime < currentTime + serviceDuration;
        durationCheckTime += interval // Move in 15-minute intervals
      ) {
        const alignedSlotStr = formatTimeToNearestInterval(
          formatTime(durationCheckTime),
          interval
        );
        if (!employeeSlots.includes(alignedSlotStr)) {
          canScheduleAll = false;
          break;
        }
      }

      if (!canScheduleAll) {
        break;
      }

      // Move current time forward for the next service
      currentTime += serviceDuration;
    }

    if (canScheduleAll) {
      availableTimeSlots.push(startTimeStr);
    }
  }

  const rescheduleSlots = getTimeSlotsForReschedule();
  rescheduleSlots.forEach((slot) => {
    if (!availableTimeSlots.includes(slot)) {
      availableTimeSlots.push(slot);
    }
  });
  availableTimeSlots.sort(); // Sort to maintain order

  return availableTimeSlots;
};

export const useBookTime = () => {
  const { nameKey, shopKey }: any = useParams();
  const query = useQuery();
  const cartUid = query.get("cart");
  const from = query.get("from");
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const timezone = cart?.shop?.timezone;

  const currentShopTime = useMemo(() => {
    return timezone ? toZonedTime(new Date(), timezone.timeZoneId) : new Date();
  }, [timezone]);

  const [currentWeekStart, setCurrentWeekStart] = useState(() => {
    // Initialize to the start of the current week (e.g., Sunday)
    const now = currentShopTime;
    const dayOfWeek = now.getDay(); // 0 (Sun) to 6 (Sat)
    const start = new Date(now);
    start.setDate(now.getDate() - dayOfWeek);
    start.setHours(0, 0, 0, 0);
    return start;
  });

  const daysOfWeek = useMemo(() => {
    // Generate an array of 7 dates representing the current week
    return Array.from({ length: 7 }, (_, i) => addDays(currentWeekStart, i));
  }, [currentWeekStart]);

  const [isLoading, setIsLoading] = useState(false);
  const [cartServices, setCartServices] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
  const [datesAvailability, setDatesAvailability] = useState<{
    [key: string]: boolean;
  }>({});
  const [showDualPricingModal, setShowDualPricingModal] = useState(false);

  const [weekTimeSlots, setWeekTimeSlots] = useState<{
    [date: string]: string[];
  }>({});

  const [appointmentGroupToReschedule, setAppointmentGroupToReschedule] =
    useState<any>(null);

  const [hasCheckedNextWeek, setHasCheckedNextWeek] = useState(false);

  const navigateToService = () => {
    const serviceUrl = generatePath(Path.BOOK_SERVICE, {
      nameKey,
      shopKey,
    } as any);

    navigate(`${serviceUrl}?${query.toString()}`);
  };

  const navigateToStaff = () => {
    const staffUrl = generatePath(Path.BOOK_STAFF, {
      nameKey,
      shopKey,
      serviceId: "null",
    } as any);

    navigate(`${staffUrl}?${query.toString()}`);
  };

  const handlePreviousWeek = () => {
    setCurrentWeekStart((prev) => addDays(prev, -7));
    setHasCheckedNextWeek(false); // Reset when manually navigating weeks
  };

  const handleNextWeek = () => {
    setCurrentWeekStart((prev) => addDays(prev, 7));
    setHasCheckedNextWeek(false); // Reset when manually navigating weeks
  };

  const updateCartWithTime = async () => {
    try {
      if (!selectedDate || !selectedTimeSlot) {
        console.warn("Selected date or time slot is missing.");
        return;
      }

      const startDate = selectedDate.toISOString().split("T")[0];

      // Construct the start time string
      const formattedStartTime = `${startDate}T${selectedTimeSlot}`;

      await dispatch(
        updateCart({
          uid: cartUid,
          startTime: formattedStartTime,
        } as any) as any
      );

      if (account.dualPricingEnabled) {
        setShowDualPricingModal(true);
      } else {
        navigateToOrderUrl();
      }
    } catch (err) {
      console.error("Error updating cart with time:", err);
    }
  };

  const onDualPricingPayBy = (method: DualPricingPaymentTypes) => {
    switch (method) {
      case DualPricingPaymentTypes.CARD:
        navigateToOrderUrl(DualPricingPaymentTypes.CARD);
        break;
      case DualPricingPaymentTypes.CASH:
        navigateToOrderUrl(DualPricingPaymentTypes.CASH);
        break;
      default:
        return;
    }
  };

  const navigateToOrderUrl = (dualPricingMethod?: DualPricingPaymentTypes) => {
    if (dualPricingMethod) {
      query.set("dualPricingMethod", dualPricingMethod);
    }
    const orderUrl = generatePath(Path.ORDER, {
      nameKey,
      shopKey,
    });
    navigate(orderUrl + `?${query.toString()}`);
  };

  // Initialize: fetch account and cart data
  const init = async () => {
    const fallBackUrl = generatePath(Path.BOOK_SERVICE, {
      nameKey,
      shopKey,
    });

    if (!cartUid) {
      return navigate(fallBackUrl);
    }

    try {
      setIsLoading(true);
      await dispatch(getAccount({ nameKey }) as any).unwrap();
      const { merchantServices, status } = await dispatch(
        getCart({ uid: cartUid }) as any
      ).unwrap();

      if (status === CartStatus.COMPLETED) {
        if (from === "bookService") return;
        return navigate(fallBackUrl);
      }

      setCartServices(merchantServices);
    } catch (error) {
      console.error("Initialization error:", error);
    } finally {
      setIsLoading(false);
    }

    if (query.get("appointmentGroupToReschedule")) {
      try {
        const appointmentGroup = await dispatch(
          getAppointmentGroupByBookingToken({
            bookingToken: query.get("appointmentGroupToReschedule"),
          } as any) as any
        ).unwrap();
        if (appointmentGroup) {
          setAppointmentGroupToReschedule(appointmentGroup);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    }
  };

  const getTimeSlotsForReschedule = () => {
    if (appointmentGroupToReschedule && selectedDate) {
      const rescheduleDate = preserveUtcTimeToLocal(
        appointmentGroupToReschedule.startTime
      );
      const isSameDayReschedule = isSameDay(rescheduleDate, selectedDate);

      if (isSameDayReschedule) {
        const rescheduleStartTime = format(rescheduleDate, "HH:mm");
        const rescheduleEndTime = format(
          addMinutes(rescheduleDate, appointmentGroupToReschedule.duration),
          "HH:mm"
        );

        // Use generateTimeSlots to split into 15-minute increments
        return generateTimeSlots(
          rescheduleStartTime,
          rescheduleEndTime,
          15
        ).map((slot) => slot.split(" - ")[0]);
      }
    }
    return [];
  };

  useEffect(() => {
    const nextAvailableDate = daysOfWeek.find((date) => {
      const dateKey = date.toISOString().split("T")[0];
      return datesAvailability[dateKey];
    });
    if (nextAvailableDate) {
      setSelectedDate(nextAvailableDate);
    }
  }, [datesAvailability, currentWeekStart]);

  // Fetch data on mount
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Subscribe to schedule updates via ActionCable (assuming Rails backend with ActionCable)
  useEffect(() => {
    if (!cart.shop) return;
    const subscription = cable.subscriptions.create(
      {
        channel: "SchedulesChannel",
        accountId: cart.shop.account.id,
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (data) => {
          init();
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.shop]);

  // **Modified useEffect to Handle Availability and Next Week Check**
  useEffect(() => {
    const fetchAvailabilityData = async () => {
      if (cartServices.length === 0) return;
      setIsLoading(true);

      try {
        const employeeIds = Array.from(
          new Set(cartServices.map((service) => service.employee.id))
        );

        const datesToFetch = daysOfWeek;

        // Fetch schedules for all employees and dates
        const schedules = await getEmployeeSchedulesForDates(
          dispatch,
          employeeIds,
          datesToFetch,
          nameKey
        );

        const availabilityMap: { [key: string]: boolean } = {};
        const timeSlotsMap: { [key: string]: string[] } = {};

        for (const date of datesToFetch) {
          const availableTimeSlots = findAvailableTimeSlotsForDate(
            schedules,
            cartServices,
            date,
            15, // interval in minutes
            currentShopTime,
            getTimeSlotsForReschedule
          );

          const dateKey = date.toISOString().split("T")[0];

          availabilityMap[dateKey] =
            availableTimeSlots.length > 0 && date >= startOfDay(new Date());

          timeSlotsMap[dateKey] = availableTimeSlots;
        }

        const hasAvailability = Object.values(availabilityMap).some(Boolean);

        if (!hasAvailability && !hasCheckedNextWeek) {
          // No availability in current week, check next week
          const nextWeekStart = addDays(currentWeekStart, 7);
          setCurrentWeekStart(nextWeekStart);
          setHasCheckedNextWeek(true); // Prevent further checks
          return; // Exit to allow useEffect to re-run with updated week
        }

        // If availability is found, update the state
        setDatesAvailability(availabilityMap);
        setWeekTimeSlots(timeSlotsMap);
      } catch (error) {
        console.error("Error fetching availability data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAvailabilityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartServices, currentWeekStart, hasCheckedNextWeek]);

  // Update availableTimeSlots when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      const dateKey = selectedDate.toISOString().split("T")[0];
      setAvailableTimeSlots(weekTimeSlots[dateKey] || []);
    }
  }, [selectedDate, weekTimeSlots]);

  return {
    updateCartWithTime,
    isLoading,
    cartServices,
    selectedDate,
    setSelectedDate,
    selectedTimeSlot,
    setSelectedTimeSlot,
    currentShopTime,
    timezone,
    cart,
    handlePreviousWeek,
    handleNextWeek,
    daysOfWeek,
    availableTimeSlots,
    currentWeekStart,
    datesAvailability,
    navigateToService,
    navigateToStaff,
    account,
    appointmentGroupToReschedule,
    showDualPricingModal,
    setShowDualPricingModal,
    onDualPricingPayBy,
  };
};
